import React, {useEffect, useState} from "react";
import "./Style.scss";
import Loginscreen from "./Components/Loginscreen.js";
import appConfig from "./config/mainConfig_Srb.json";
//import appConfig from "./config/mainConfig_Petrusch.json";
//import appConfig from "./config/mainConfig_Petrusch_dev.json";
//import appConfig from "./config/mainConfig_Srb_dev.json";
import TOTP_generateToken from "./Helper/TOTP.js";
import Wichtelsite from "./Components/Wichtelsite";

const getWishesFromServer = async (user, config) => {
    const payload = {
        method: "getWishesByID",
        id: user.userID,
        userName: user.value,
        config_id: config.config_id,
        token: TOTP_generateToken(config.requestConfig.token),
    };

    const endpoint = config.requestConfig.endpoint;

    const response = await fetch(endpoint, {
        method: "POST",
        headers: {
            Accept: "*/*",
        },
        body: JSON.stringify(payload),
    }).catch((error) => console.log(error));

    const data = await response.json();
    return data.wishes || [];
};

const getTarget = async (user, config) => {
    const payload = {
        method: "getTargetById",
        id: user.userID,
        config_id: config.config_id,
        token: TOTP_generateToken(config.requestConfig.token),
    };

    const endpoint = config.requestConfig.endpoint;

    const response = await fetch(endpoint, {
        method: "POST",
        headers: {
            Accept: "*/*",
        },
        body: JSON.stringify(payload),
    });

    return response.json();
};

function App() {
    const [currentUser, setCurrentUser] = useState(false);
    const [savedWishes, setSavedWishes] = useState("");
    const [currentTarget, setCurrentTarget] = useState("");
    const [selectableUsers, setSelectableUsers] = useState("");
    const [config, setConfig] = useState("");
    const [isFirstVisit, setIsFirstVisit] = useState(true)

    useEffect(() => {
        const payload = {
            method: "getConfigByID",
            config_id: appConfig.config_id,
            token: TOTP_generateToken(appConfig.token),
        };

        const endpoint = appConfig.endpoint;

        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "*/*",
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (response.ok) return response.json()
            })
            .then((config) => {
                setSelectableUsers(config.selectableUsers)
                setConfig(config)
            })
            .catch((error) => console.log(error));
    }, [appConfig])

    const changeUser = async (selection) => {
        // get gifts from server
        if (selection) {
            const wishes = await getWishesFromServer(selection, config);
            const target = await getTarget(selection, config);
            target.wishes = await getWishesFromServer(target, config);
            setSavedWishes(wishes);
            setCurrentTarget(target);
            setIsFirstVisit(false)
        }

        setCurrentUser(selection);
    };

    if (!config) return null


    return (
        <main>
            {!currentUser && <Loginscreen
                family={config.family}
                changeUser={changeUser}
                selectableUsers={selectableUsers}
                delay={isFirstVisit}
                activated={!isFirstVisit}
            />
            }
            {currentUser &&
                <Wichtelsite
                    changeUser={changeUser}
                    user={currentUser}
                    savedWishes={savedWishes}
                    target={currentTarget}
                    endpoint={config.requestConfig.endpoint}
                    token={config.requestConfig.token}
                    config_id={config.config_id}
                />
            }
            <div className="snow-ground bottom front">
            </div>
            <div className="snow-ground bottom middle">
            </div>
            <div className="snow-ground bottom behind">
            </div>

        </main>
    );
}

export default App;
